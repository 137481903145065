module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"withWebp":true,"maxWidth":900,"backgroundColor":false,"linkImagesToOriginal":true,"tracedSVG":true,"lazy":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-breadcrumb/gatsby-browser.js'),
      options: {"plugins":[],"useAutoGen":true,"autoGenHomeLabel":"Strona główna","exclude":["**/dev-404-page/**","**/404/**","**/404.html","**/offline-plugin-app-shell-fallback/**","**/blog/**","**/festung-breslau/**"],"crumbLabelUpdates":[{"pathname":"/turystyka","crumbLabel":"Turystyka"},{"pathname":"/turystyka/brandenburgia","crumbLabel":"Brandenburgia"},{"pathname":"/turystyka/saksonia","crumbLabel":"Saksonia"},{"pathname":"/turystyka/dolny-slask","crumbLabel":"Dolny Śląsk"},{"pathname":"/it","crumbLabel":"IT"},{"pathname":"/it/js","crumbLabel":"JavaScript"},{"pathname":"/it/kryptologia","crumbLabel":"Kryptologia"},{"pathname":"/it/internet","crumbLabel":"Internet"},{"pathname":"/it/historia","crumbLabel":"Historia IT"},{"pathname":"/it/web-accessibility","crumbLabel":"Web Accessibility"},{"pathname":"/it/kryptologia","crumbLabel":"Kryptologia"},{"pathname":"/it/css","crumbLabel":"CSS"},{"pathname":"/it/cms","crumbLabel":"CMS"},{"pathname":"/misc","crumbLabel":"Misc"},{"pathname":"/misc/deutsch","crumbLabel":"Deutsch"},{"pathname":"/misc/francais","crumbLabel":"Français"}],"trailingSlashes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-54KSKM7","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Dygresje.info Homepage","short_name":"dygresje.info","start_url":"/","background_color":"#f7f0eb","theme_color":"#a2466c","display":"standalone","description":"Dygresje.info - Tadeusz Dudkowski Homepage","lang":"pl","icon":"src/images/maskable-icon-512.png","icons":[{"src":"favicon.ico","sizes":"16x16","type":"image/x-icon"},{"src":"src/images/maskable-icon-512.png","sizes":"192x192 512x512","type":"image/png","purpose":"any maskable"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"b7847f87a384b374c6b82374bf88e4d8"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
